<template>
  <p class="path" @click="popup" :style="{'width': `${windowWidth}px`}" v-if="this.$route.name !== 'Home' && this.$route.name !== 'Help' && this.$route.name !== 'Select'">
    <span class="toolong" v-if="tooLong">...</span>
    <span id="breadcrumb_path" :style="[tooLong ? { 'float': 'right' } : { 'float': 'left' }]"> {{ breadcrumb }} </span>
  </p>
</template>

<script>
export default {
  name: 'breadcrumb',
  data() {
    return {
      tooLong: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  computed: {  
    breadcrumb() {
      if (!this.$store.state.targetPath && this.$route.name !== 'Home' && localStorage.location !== undefined) {
        return JSON.parse(localStorage.location)
      } else {
        return this.$store.state.targetPath
      }
    },
  },
  methods: {
    popup() {
      this.$store.state.breadcrumbPopup = true
    },
    adjustBreadcrumbs() {
      let el = document.getElementById('breadcrumb_path')
      if (el != null) {
        let breadcrumbWidth = el.offsetWidth
        if (this.windowWidth < (breadcrumbWidth + 20)) {
          this.tooLong = true
        } else {
          this.tooLong = false
        }
      }
    },
  },
  updated() {
    this.adjustBreadcrumbs();
  },
  mounted() {
    this.adjustBreadcrumbs();
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;

      this.adjustBreadcrumbs();
    });
  },
}
</script>

<style lang="scss">
.toolong{
  position: absolute;
  left: 0px;
  height: inherit;
  padding: 0 3px 0 0;
  text-indent: 8px;
  background-color: #ececec;
  direction: ltr;
}
.path{
  color: #787878;
  background-color: #ececec;
  margin: 0;
  display: block;
  padding-left: 32px;
  padding-right: 16px;
  top: 64px;
  height: 36px;
  line-height: 36px;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
  // direction: rtl;
  text-align: left;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  @media screen and (min-width: 2701px) and (max-width: 3840px){
    padding-left: 100px;
    height: 80px;
    span{
      height: 80px;
      line-height: 80px;
      font-size: 36px;
    }
  }
  // DESKTOP
  @media screen and (min-width: 1025px) and (max-width: 2700px){
    padding-left: 50px;
    height: 46px;
    span{
      height: 46px;
      line-height: 46px;
    }
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    padding-left: 40px;
    height: 44px;
    span{
      height: 44px;
      line-height: 44px;
    }
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    padding-left: 30px;
    height: 43px;
    span{
      height: 43px;
      line-height: 43px;
    }
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    padding-left: 15px;
    height: 41px;
    span{
      height: 41px;
      line-height: 41px;
    }
  }
}
@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
  and (min-height: 961px) and (orientation: portrait){
  .path{
    top: 0px;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
  screen and (max-height: 500px) and (orientation: landscape) {
  .path{
    top: 0px;
    float: right;
    span{
      float: right;
      overflow: hidden;
    }
  }
}
</style>
